/* @import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@200;300;400;500;700&family=Inter:wght@300;400;500;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@100;300;400;500;600&display=swap');

/* :root{
  --main-color: #EA3323
} */

html,body{
  font-size: 18px;
  line-height: 1.6;
  font-family: 'Epilogue', sans-serif !important;
  /* font-weight: 300; */
}

a:hover, a:visited, a:link, a:active
{
    text-decoration: none;
    color: inherit
}

h1,.h1{
  font-family: 'Epilogue', sans-serif;

}

h2,.h2{
  font-family: 'Epilogue', sans-serif;
  font-weight: 500;
}

h3,.h3{
  font-family: 'Epilogue', sans-serif;
}

.overlay_dark{
  background-color: #000;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0.3;
}

.title_table figure {
  margin: 0;
}

.title_table figure table td {
  padding-right: 40px;
}

/* about table */
.about_table figure {
  margin: 0;
}
.about_table table{
  table-layout: fixed;
  width:100%;
}
.about_table table tbody{
  vertical-align: inherit;
}
.about_table figure table td {
  padding-right: 10px;
}
/* about table */



@media only screen and (max-width: 1536px) {
  .leftContainerSlider{
    padding-left: 24px;
  }
}


@media only screen and (min-width: 1536px) {
  .leftContainer{
    max-width: 920px;
    padding-right: 30px;
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    display: block;
  }
  .rightContainer{
    max-width: 620px;
    padding-left: 30px;
    width: 100%;
    margin-right: auto;
    box-sizing: border-box;
    display: block;
  }
  .leftContainerFull{
    max-width: 785px;
    padding-right: 30px;
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    display: block;
  }
  .rightContainerFull{
    max-width: 785px;
    padding-left: 30px;
    width: 100%;
    margin-right: auto;
    box-sizing: border-box;
    display: block;
  }
  .leftContainerSlider{
    /* max-width: 768px; */
    padding-left: 24px;
    margin-right: auto;
    box-sizing: border-box;
    display: block;
  }
}


@media only screen and (min-width: 1700px) {
  .leftContainerSlider{
    padding-left: 100px;
  }
}

@media only screen and (min-width: 1900px) {
  .leftContainerSlider{
    padding-left: 200px;
  }
}

@media only screen and (min-width: 2000px) {
  .leftContainerSlider{
    padding-left: 15%;
  }
}



@media only screen and (min-width: 600px) {
  .leftContainer{
    padding-left: 24px;
    padding-right: 24px;
  }
  .rightContainer{
    padding-left: 24px;
    padding-right: 24px;
  }
  .leftContainerFull{
    padding-left: 24px;
    padding-right: 24px;
  }
  .rightContainerFull{
    padding-left: 24px;
    padding-right: 24px;
  }

}


@media only screen and (max-width: 600px) {
  body {
    font-size: 14px;
  }
  .leftContainer{
    padding-left: 16px;
    padding-right: 16px;
  }
  .rightContainer{
    padding-left: 16px;
    padding-right: 16px;
  }
  .leftContainerFull{
    padding-left: 16px;
    padding-right: 16px;
  }
  .rightContainerFull{
    padding-left: 16px;
    padding-right: 16px;
  }
  .leftContainerSlider{
    padding-left: 16px;
  }

  .title_table table  {
    display: grid;
  }

  .title_table table tbody {
    display: grid;
  }
  .title_table table tbody tr {
    display: grid;
  }
  .title_table figure table td {
    padding-right: 0px;
    padding-bottom: 20px;
    text-align: center;
  }

  /* about table */
  .about_table table  {
    display: grid;
  }

  .about_table table tbody {
    display: grid;
  }
  .about_table table tbody tr {
    display: grid;
  }
  .about_table figure table td {
    padding-right: 0px;
    padding-bottom: 20px;
  }
  /* about table */

}